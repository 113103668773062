// @ubo
import { Link, LosseBlogBink, LosseLink, LossePlaatjie, useLosseBlogBink, useLosseLanguage } from '@ubo/losse-sjedel'

// Types
import type { KnowledgeBaseItem, PageInfo, Page_Flexcontent_Flex_Posts } from '~/graphql/types'

// Components
import Slider, { Slide } from '~/components/elements/Slider'
import EndlessContainer from '~/components/elements/EndlessContainer'
import { Mousewheel, Navigation } from 'swiper/modules'
import Chevron from '~/components/elements/svg/Chevron'
import StripeSmall from '~/components/elements/svg/StripeSmall'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import PostsSearch from '~/components/elements/PostsSearch'
import PostsFilters from '~/components/elements/PostsFilters'
import { convertDate } from '../Featured/FeaturedWebinarLatest'

export default function PostsKnowledgeBase({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section
      data-component="PostsKnowledgeBase"
      data-scrollindicatortitle={fields.scrollIndicatorTitle ?? undefined}
      id={fields.sectionId ?? undefined}
      className="relative overflow-hidden bg-ubo-delibird"
    >
      <LosseBlogBink pageInfo={fields.posts?.pageInfo as PageInfo}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

const SORTING = ['Webinars', 'Whitepapers', 'Templates', 'U-Books', 'Talkshows']

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [, locale] = useLosseLanguage()
  const [hasReachedEnd, setHasReachedEnd] = useState(-1)
  const [showGrid, setShowGrid] = useState(-1)
  const bb = useLosseBlogBink()
  const currentDate = new Date().toISOString().slice(0, 10)

  const upcommingWebinar = fields.posts?.edges?.find((edge) => {
    return (edge?.node as KnowledgeBaseItem)?.categories?.edges?.find((edge) => edge?.node?.databaseId === 100)
  }) as { node: KnowledgeBaseItem }

  return (
    <>
      {/* <div className="bg-ubo-whiscash pb-20">
        <div className="container">
          <div className="grid grid-cols-3 items-center gap-x-24">
            <div className="col-span-3 flex h-full items-end md:col-span-1">
              <PostsSearch />
            </div>
            <div className="col-span-3 md:col-span-2">
              <PostsFilters filters={fields.filters?.filter((edge) => edge?.parentDatabaseId !== 98)} />
            </div>
          </div>
        </div>
      </div> */}
      {/* {!bb.hasActiveFilters && fields.styletype === 'knowledge_base' && !!upcommingWebinar && (
        <div className="relative bg-ubo-whiscash py-10 lg:py-20">
          <div className="container relative z-10">
            <div className="grid-cols-12 gap-10 text-white sm:grid sm:px-10 2xl:gap-20 2xl:px-20">
              <div className="relative z-10 col-span-8 pt-10 xl:pb-10 2xl:col-span-7">
                <h2 className="content-stroke-light font-september text-4xl font-black uppercase lg:text-6xl">
                  <em>{locale === 'nl_NL' ? 'Volgende webinar' : 'Next webinar'}</em>
                </h2>
                <h3 className="mt-4 pl-10 text-lg font-black sm:text-xl xl:text-2xl">
                  {convertDate(upcommingWebinar?.node?.recap?.date as string)} {upcommingWebinar?.node?.recap?.time}
                </h3>
                <h4 className="mt-10 text-xl font-black sm:text-2xl lg:text-3xl">{upcommingWebinar?.node?.title}</h4>
                <LosseLink to={upcommingWebinar?.node?.uri} className="btn btn--light mt-10 px-4 py-1">
                  <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3">
                    <path
                      d="M1 4.8C0.613401 4.8 0.3 5.1134 0.3 5.5C0.3 5.8866 0.613401 6.2 1 6.2L1 4.8ZM16.495 5.99498C16.7683 5.72161 16.7683 5.27839 16.495 5.00503L12.0402 0.550253C11.7668 0.276886 11.3236 0.276886 11.0503 0.550253C10.7769 0.823621 10.7769 1.26684 11.0503 1.5402L15.0101 5.5L11.0503 9.4598C10.7769 9.73317 10.7769 10.1764 11.0503 10.4497C11.3236 10.7231 11.7668 10.7231 12.0402 10.4497L16.495 5.99498ZM1 6.2L16 6.2L16 4.8L1 4.8L1 6.2Z"
                      fill="currentColor"
                    />
                  </svg>
                  {locale === 'nl_NL' ? 'Inschrijven' : 'Register'}
                </LosseLink>
              </div>
              <div className="-top-8 right-4 z-0 col-span-4 max-sm:absolute 2xl:col-span-5">
                <div className="aspect-square w-full max-sm:h-[150px] max-sm:w-[150px] 2xl:max-w-[450px]">
                  <svg
                    width="483"
                    height="483"
                    viewBox="0 0 483 483"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-full w-full"
                  >
                    <g clipPath="url(#clip0_3101_3091)">
                      <path d="M265.438 47.9043V0H483V217.562H435.096V47.9043H265.438Z" fill="#070744" />
                      <path d="M47.9043 217.562H0V0H217.562V47.9043H47.9043V217.562Z" fill="#070744" />
                      <path d="M217.562 435.096V483H0V265.438H47.9043V435.096H217.562Z" fill="#070744" />
                      <path d="M435.096 265.438H483V483H265.438V435.096H435.096V265.438Z" fill="#070744" />
                      <path d="M265.438 95.95H217.534V387.728H265.438V95.95Z" fill="#070744" />
                      <path d="M387.389 217.901H95.6108V265.805H387.389V217.901Z" fill="#070744" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="pointer-events-none absolute bottom-0 left-0 h-24 w-full bg-ubo-delibird max-xl:hidden 2xl:h-28" />
        </div>
      )} */}

      <div className="container py-20 ">
        <div className="mb-12 lg:mb-24">
          <h2 className="text-stroke stroke-dark stroke-ubo font-september text-5xl font-black uppercase sm:mr-4 lg:text-10xl">Webinars</h2>
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
            {fields?.posts?.edges
              ?.filter((edge: any) => edge?.node?.categories?.edges[1]?.node?.databaseId === 100)
              ?.map((edge: any, index: number) => {
                return (
                  <div key={edge.title} className="relative !h-auto overflow-hidden">
                    <LosseLink to={edge?.node?.recap?.customLink?.url} className="group h-full w-full bg-ubo-delibird" key={index}>
                      <LossePlaatjie
                        src={edge.node?.recap?.thumbnail}
                        className="h-[350px] w-full object-cover object-top transition-opacity group-hover:opacity-70 md:h-[] md:object-center lg:h-[496px]"
                        maxWidth={1240}
                      />
                      <div className="absolute bottom-0 flex h-full w-full  flex-row items-end justify-center bg-gradient-to-t from-ubo-roggenrola via-transparent p-4 sm:p-7 xl:justify-end">
                        <div>
                          <div className="flex flex-col items-start justify-between">
                            <div className="mb-3 rounded-full bg-white p-2 px-4 text-sm text-ubo-whiscash">{edge?.node?.recap?.tag}</div>
                            <div className="flex items-center justify-between">
                              <h3 className="pr-2 text-lg font-bold leading-[1.5rem] text-ubo-delibird xs:pr-8 xs:text-xl md:text-xl">
                                {edge.node?.title}
                              </h3>
                              <Chevron className="h-3 w-3 xs:h-7 xs:w-7 sm:h-auto sm:w-auto" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </LosseLink>
                  </div>
                )
              })}
          </div>
          {fields?.link && (
            <Link to={fields?.link?.url || ''} className="btn btn--primary--filled mt-10">
              {fields?.link?.title}
            </Link>
          )}
        </div>

        {fields.filters
          ?.filter((edge) => edge?.parentDatabaseId === 98 && edge?.databaseId !== 100)
          ?.sort((a, b) => SORTING.indexOf(a?.name || '') - SORTING.indexOf(b?.name || ''))
          ?.map((filter, filterIndex) => {
            const posts =
              fields.posts?.edges?.filter((edge) => {
                const knowledgeBaseItem = edge?.node as KnowledgeBaseItem
                const activeGroupCategories =
                  knowledgeBaseItem?.categories?.edges.filter((edge) => edge?.node?.databaseId === filter?.databaseId) || []

                if (bb.hasActiveFilters) {
                  const activeFilterCategories =
                    knowledgeBaseItem?.categories?.edges.filter((edge) => bb.isFilterActive(edge?.node?.databaseId)) || []

                  return activeGroupCategories.length > 0 && activeFilterCategories.length > 0
                }

                return activeGroupCategories.length > 0
              }) || []

            if (posts.length === 0) return null

            return (
              <div key={filter?.name}>
                <div className="mb-4 flex flex-wrap items-center lg:items-end">
                  <h2 className="text-stroke stroke-dark stroke-ubo font-september text-5xl font-black uppercase sm:mr-4 lg:text-10xl">
                    {filter?.name}
                  </h2>
                  {Number(posts.length) > 2 && (
                    <button
                      type="button"
                      className="group mt-2 inline-flex items-center lg:ml-7"
                      onClick={() => {
                        if (showGrid === filterIndex) {
                          setShowGrid(-1)
                        } else {
                          setShowGrid(filterIndex)
                        }
                      }}
                    >
                      <svg
                        className={clsx(showGrid === filterIndex && '-rotate-90', 'mr-4 transition-transform')}
                        width="51"
                        height="45"
                        viewBox="0 0 51 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M28.7402 17.205L33.195 21.6598C33.4683 21.9332 33.4683 22.3764 33.195 22.6497L28.7402 27.1045C28.4668 27.3779 28.0236 27.3779 27.7503 27.1045C27.4769 26.8312 27.4769 26.3879 27.7503 26.1146L31.0101 22.8548L17.7 22.8548C17.3134 22.8548 17 22.5414 17 22.1548C17 21.7682 17.3134 21.4548 17.7 21.4548L31.0101 21.4548L27.7503 18.195C27.4769 17.9216 27.4769 17.4784 27.7503 17.205C28.0236 16.9317 28.4668 16.9317 28.7402 17.205Z"
                          fill="#070744"
                        />
                        <rect x="1" y="1" width="48.4" height="42.3095" rx="21.1548" stroke="#070744" strokeWidth="2" />
                      </svg>

                      <span className="group-hover:underline">
                        {showGrid === filterIndex ? (
                          <>
                            {locale === 'nl_NL' ? 'Toon minder ' : 'Show less '}
                            {filter?.name?.toLowerCase()}
                          </>
                        ) : (
                          <>
                            {locale === 'nl_NL' ? 'Toon alle ' : 'Show all '}
                            {filter?.name?.toLowerCase()}
                          </>
                        )}
                      </span>
                    </button>
                  )}
                </div>

                <div className="relative">
                  <div className="absolute -bottom-10 -right-44 ">
                    <StripeSmall />
                  </div>
                  <div className="mb-24 lg:ml-3">
                    <AnimatePresence>
                      {showGrid !== filterIndex && (
                        <motion.div
                          initial={{ x: '100%' }}
                          animate={{ x: 0 }}
                          exit={{ opacity: 0, position: 'absolute' }}
                          transition={{
                            bounce: 0
                          }}
                        >
                          <EndlessContainer className="relative !ml-0 max-w-8xl !pl-0">
                            <AnimatePresence>
                              {hasReachedEnd !== filterIndex && showGrid !== filterIndex && (
                                <motion.div
                                  key={`filter_${filterIndex}`}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  className="pointer-events-none absolute right-0 top-0 z-10 hidden h-full w-1/4 bg-gradient-to-l from-ubo-delibird via-ubo-delibird/20 2xl:block"
                                />
                              )}
                            </AnimatePresence>
                            <Slider
                              autoplay={false}
                              modules={[Navigation, Mousewheel]}
                              mousewheel={{
                                forceToAxis: true
                              }}
                              breakpoints={{
                                0: {
                                  slidesPerView: 1.5,
                                  spaceBetween: 20
                                },
                                768: {
                                  slidesPerView: 2.25,
                                  spaceBetween: 40
                                },
                                1024: {
                                  slidesPerView: 2.5,
                                  spaceBetween: 60
                                }
                              }}
                              onSlideChange={(slide) => {
                                if (slide.isEnd) {
                                  setHasReachedEnd(filterIndex)
                                } else {
                                  setHasReachedEnd(-1)
                                }
                              }}
                              className="max-lg:!pr-4 lg:max-2xl:!-mr-24 lg:max-2xl:!pr-24"
                            >
                              {posts.map((edge) => {
                                const knowledgeBaseItem = edge?.node as KnowledgeBaseItem

                                let upcomming = false

                                if (knowledgeBaseItem.recap?.date && knowledgeBaseItem.recap?.date >= currentDate) {
                                  upcomming = true
                                }

                                return (
                                  <Slide key={knowledgeBaseItem.title} className="relative !h-auto overflow-hidden">
                                    <LosseLink to={knowledgeBaseItem.uri} className="group h-full w-full bg-ubo-delibird">
                                      <LossePlaatjie
                                        src={knowledgeBaseItem.recap?.thumbnail}
                                        className="w-full object-cover transition-opacity group-hover:opacity-70 xl:h-[625px]"
                                        maxWidth={1240}
                                      />
                                      <div className="absolute bottom-0 flex h-full w-full flex-col items-end justify-end bg-gradient-to-t from-ubo-roggenrola via-transparent p-4 xs:flex-row xs:justify-between sm:p-7">
                                        <div>
                                          {upcomming && (
                                            <div className="btn btn--light--filled mb-6 px-4 py-2 text-base font-light text-ubo-whiscash">
                                              {convertDate(knowledgeBaseItem.recap?.date as string)}
                                            </div>
                                          )}
                                          <div className="flex items-end justify-between">
                                            <h3 className="pr-2 text-lg font-bold leading-[1.5rem] text-ubo-delibird xs:pr-8 xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                                              {knowledgeBaseItem.title}
                                            </h3>
                                            <Chevron className="h-3 w-3 xs:h-7 xs:w-7 sm:h-auto sm:w-auto" />
                                          </div>
                                        </div>
                                      </div>
                                    </LosseLink>
                                  </Slide>
                                )
                              })}
                            </Slider>
                          </EndlessContainer>
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <AnimatePresence>
                      {showGrid === filterIndex && (
                        <motion.div
                          initial={{ y: 100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          className="grid grid-cols-2 gap-2 max-sm:-mx-4 md:gap-4 lg:grid-cols-3 lg:gap-12"
                        >
                          {posts.map((edge, itemIndex) => {
                            const knowledgeBaseItem = edge?.node as KnowledgeBaseItem
                            return (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                  delay: itemIndex * 0.15
                                }}
                                key={knowledgeBaseItem.title}
                                className="relative col-span-1 !h-auto overflow-hidden"
                              >
                                <LosseLink to={knowledgeBaseItem.uri} className="group h-full w-full">
                                  <LossePlaatjie
                                    src={knowledgeBaseItem.recap?.thumbnail}
                                    className="w-full object-cover transition-opacity group-hover:opacity-70 xl:h-[625px]"
                                    maxWidth={1240}
                                  />
                                  <div className="absolute bottom-0 flex h-full w-full flex-col items-end justify-end bg-gradient-to-t from-ubo-roggenrola via-transparent p-4 xs:flex-row xs:justify-between sm:p-7">
                                    <h3 className="hyphens-auto pr-2 text-base font-bold leading-[1.5rem] text-ubo-delibird xs:pr-8 xs:text-lg sm:text-3xl sm:text-xl md:text-4xl">
                                      {knowledgeBaseItem.title}
                                    </h3>
                                    <Chevron className="h-3 w-3 xs:h-7 xs:w-7 sm:h-auto sm:w-auto" />
                                  </div>
                                </LosseLink>
                              </motion.div>
                            )
                          })}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
